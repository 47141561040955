/* eslint-disable no-shadow */
import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
// import AppsNavigation from './AppsNavigation';
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting';
// import IntlMessages from '../../util/IntlMessages';

const { SubMenu } = Menu;

const SidebarContent = () => {
  const { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  const history = useHistory();

  const getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  const getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup';
    }
    return '';
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const onClickMyContentMenu = () => {
    history.push('/mycontent/contentmanagement');
  };

  const onClickCampaignsMenu = () => {
    history.push('/mycampaigns/mycampaigns');
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {/* <SubMenu
              key="MyAccount"
              className={getNavStyleSubMenuClass(navStyle)}
              title={
                <>
                  <i className="icon icon-auth-screen" />
                  {navStyle !== NAV_STYLE_MINI_SIDEBAR && <IntlMessages id="sidebar.myaccount" />}
                </>
              }
            >
              <Menu.Item key="myaccount/accountdetails">
                <Link to="/myaccount/accountdetails">
                  <span>
                    <IntlMessages id="sidebar.myaccount.accountdetails" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="myaccount/users">
                <Link to="/myaccount/users">
                  <span>
                    <IntlMessages id="sidebar.myaccount.users" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu> */}

            <SubMenu
              key="MyCampaigns"
              className={getNavStyleSubMenuClass(navStyle)}
              id="campaigns_menu_dropdown_link"
              onTitleClick={onClickCampaignsMenu}
              title={
                <>
                  <i className="icon icon-alert" />
                  {navStyle !== NAV_STYLE_MINI_SIDEBAR && `Campaigns`}
                </>
              }
            >
              <Menu.Item key="mycampaigns/createcampaign">
                <Link to="/mycampaigns/createcampaign" id="createcampaign_page_link">
                  <span>Create Campaign</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="mycampaigns/mycampaigns">
                <Link to="/mycampaigns/mycampaigns" id="mycampaigns_page_link">
                  <span>My Campaigns</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="mycampaigns/managecampaign">
                <Link to="/mycampaigns/managecampaign" id="campaignmanagement_page_link">
                  <span>Campaign Management</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="MyContent"
              className={getNavStyleSubMenuClass(navStyle)}
              onTitleClick={onClickMyContentMenu}
              id="mycontent_menu_dropdown_link"
              title={
                <>
                  <i className="icon icon-data-display" />
                  {navStyle !== NAV_STYLE_MINI_SIDEBAR && `My Content`}
                </>
              }
            >
              <Menu.Item key="mycontent/contentmanagement">
                <Link to="/mycontent/contentmanagement" id="contentmanagement_page_link">
                  <span>Content Management</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
